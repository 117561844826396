import {
  SurfaceForm,
  InputFilterable,
  InputDate,
  InputDropdown,
  Actions,
  InputTime,
  InputText,
  InputTextarea,
} from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";

import preventDefault from "@lib/preventDefault";

const { T } = translationWithRoot("prevention.agency_service_records");

/**
 * @param {Array} methodsOfEngagement
 * @param {Array} serviceRates
 * @param {Array} serviceReferralRecipients
 */
const AgencyServiceRecordForm = ({
  methodsOfEngagement,
  serviceRates,
  serviceReferralRecipients,
}) => {
  const { setFormAttribute } = useBintiForm(null, {
    initialValue: {},
  });

  const handleSubmit = () => {};

  return (
    <SurfaceForm
      actions={<Actions />}
      title={<T t="log_new" />}
      onSubmit={preventDefault(handleSubmit)}
    >
      <InputDate
        label={<T t="service_date" />}
        onChange={setFormAttribute("serviceDate")}
        required
      />
      <InputFilterable
        isMulti
        label={<T t="recipients" />}
        onChange={setFormAttribute("recipientIds")}
        values={serviceReferralRecipients}
      />
      <InputDropdown
        hideBlank
        label={<T t="select_rate" />}
        onChange={setFormAttribute("serviceRateId")}
        required
        values={serviceRates}
      />
      <InputDropdown
        hideBlank
        label={<T t="method_of_engagement" />}
        onChange={setFormAttribute("methodOfEngagement")}
        values={methodsOfEngagement}
      />
      <InputTime
        label={<T t="start_time" />}
        onChange={setFormAttribute("startTime")}
      />
      <InputTime
        label={<T t="end_time" />}
        onChange={setFormAttribute("endTime")}
      />
      <InputText
        label={<T t="time_billed" />}
        type="number"
        min="0"
        step="15"
        onChange={setFormAttribute("timeBilled")}
      />
      <InputText
        label={<T t="units_utilized" />}
        onChange={setFormAttribute("unitsUtilized")}
        type="number"
      />
      <InputTextarea
        label={<T t="notes" />}
        onChange={setFormAttribute("notes")}
      />
    </SurfaceForm>
  );
};

AgencyServiceRecordForm.propTypes = {
  methodsOfEngagement: PropTypes.array,
  serviceRates: PropTypes.array,
  serviceReferralRecipients: PropTypes.array,
};

export default AgencyServiceRecordForm;
