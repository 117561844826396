import classnames from "classnames";
import PropTypes from "prop-types";
import { createRef, Component, Fragment } from "react";

import SummaryItem from "./SummaryItem";
import styles from "./SummaryItem.module.scss";

/**
 * A group of tiles on the homepage that display a summary of data, usually
 * a count of items that need attention.
 */
class SummaryItemGroup extends Component {
  constructor({ summaryItemGroups = {}, maximizeWidth = false, ...args }) {
    super({ summaryItemGroups, maximizeWidth, ...args });
    this.containerRef = createRef();
    this.sigRefs = Object.keys(summaryItemGroups).map(() => createRef());

    this.state = {
      positions: [],
    };
  }

  componentDidMount() {
    this.calculatePositions();
    $(window).on(this.calculatePositions);
  }

  calculatePositions = () => {
    let row = -1;
    let col = 0;
    let lastTop;
    const positions = this.sigRefs.map(({ current }) => {
      const { top } = current.getBoundingClientRect();
      if (top === lastTop) {
        col += 1;
      } else {
        col = 0;
        lastTop = top;
        row += 1;
      }

      return {
        row: row,
        col: col,
      };
    });

    this.setState({ positions });
  };

  render() {
    let lastRow;
    const { positions } = this.state;
    return (
      <div className={styles.summaryItemGroupContainer} ref={this.containerRef}>
        <div className={styles.title} data-testid="summary-item-group-title">
          {this.props.title}
        </div>
        <div className={styles.summaryItemGroups}>
          {Object.keys(this.props.summaryItemGroups).map((key, index) => {
            const { row, col } = positions[index] || {};
            const summaryItemGroup = this.props.summaryItemGroups[key];

            const fragment = (
              <Fragment key={key}>
                {row !== lastRow && <hr key={index}></hr>}

                <div
                  className={classnames(styles.summaryItemGroup, {
                    [styles.verticalSeparator]: col > 0,
                  })}
                  ref={this.sigRefs[index]}
                >
                  <div className={styles.subtitle}>{key}</div>
                  <div className={styles.summaryItemContainer}>
                    {summaryItemGroup.map(summaryItem => (
                      <SummaryItem {...summaryItem} key={summaryItem.label} />
                    ))}
                  </div>
                </div>
              </Fragment>
            );

            lastRow = row;
            return fragment;
          })}
        </div>
      </div>
    );
  }
}

SummaryItemGroup.propTypes = {
  title: PropTypes.string,
  summaryItemGroups: PropTypes.object,
  maximizeWidth: PropTypes.bool,
};
export default SummaryItemGroup;
